<template>
  <v-footer
    absolute
    padless
    app
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          align="center"
        >
          OCF x Polyenco
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          align="center"
        >
          Guillaume VILLEDIEU - Adrien GARBANI
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>