import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAuthenticated ? _c(VAppBar, {
    attrs: {
      "color": "primary",
      "dark": "",
      "flat": "",
      "fixed": "",
      "app": ""
    }
  }, [_c(VAppBarNavIcon, {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c(VToolbarTitle, [_c(VImg, {
    attrs: {
      "src": require("../assets/logo_header_footer.png"),
      "alt": "OCF",
      "height": "auto",
      "width": "247px"
    }
  })], 1)], 1) : _vm._e(), _c(VNavigationDrawer, {
    attrs: {
      "color": "primary",
      "temporary": "",
      "app": ""
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c(VList, {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c(VListItemGroup, {
    attrs: {
      "active-class": "deep-yellow--text text--accent-4"
    },
    model: {
      value: _vm.group,
      callback: function ($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'EstimateList'
      }
    }
  }, [_c(VImg, {
    attrs: {
      "src": require("../assets/logo_header_footer.png"),
      "alt": "OCF",
      "height": "auto",
      "width": "247px"
    }
  })], 1), _c(VBtn, {
    staticClass: "mx-5 my-1 mt-3 white--text",
    attrs: {
      "plain": "",
      "to": {
        name: 'EstimateList'
      }
    }
  }, [_vm._v(" dossiers en cours ")]), _vm.isAllowedSUPERVISOR ? _c(VBtn, {
    staticClass: "mx-5 my-1 mt-3 white--text",
    attrs: {
      "plain": "",
      "to": {
        name: 'AllEstimateArchivedList'
      }
    }
  }, [_vm._v(" archives ")]) : _c(VBtn, {
    staticClass: "mx-5 my-1 mt-3 white--text",
    attrs: {
      "plain": "",
      "to": {
        name: 'EstimateArchivedList'
      }
    }
  }, [_vm._v(" archives ")]), _vm.isAllowedSUPERADMIN ? _c(VMenu, {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "mx-5 my-1 white--text",
          attrs: {
            "plain": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" back office ")])];
      }
    }], null, false, 2222240535)
  }, [_c(VList, [_c(VListItem, {
    attrs: {
      "to": {
        name: 'BackofficeShowcase'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Mobiliers")])], 1), _c(VListItem, {
    attrs: {
      "to": {
        name: 'BackofficeOption'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Options")])], 1), _c(VListItem, {
    attrs: {
      "to": {
        name: 'BackOfficeExportParam'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Paramètres d'export")])], 1)], 1)], 1) : _vm._e(), _vm.isAllowedSUPERADMIN ? _c(VMenu, {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "mx-5 my-1 white--text",
          attrs: {
            "plain": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" profils ")])];
      }
    }], null, false, 1363449729)
  }, [_c(VList, [_c(VListItem, {
    attrs: {
      "to": {
        name: 'AllEstimateList'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Suivi commercial")])], 1), _c(VListItem, {
    attrs: {
      "to": {
        name: 'UserList'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Liste des utilisateurs")])], 1), _c(VListItem, {
    attrs: {
      "to": {
        name: 'Log'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Consulter les Logs")])], 1), _c(VListItem, {
    attrs: {
      "to": {
        name: 'UserProfile'
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Profil")])], 1)], 1)], 1) : _vm._e(), !_vm.isAllowedSUPERADMIN && _vm.isAllowedSUPERVISOR ? _c(VBtn, {
    staticClass: "mx-5 my-1 white--text",
    attrs: {
      "plain": "",
      "to": {
        name: 'AllEstimateList'
      }
    }
  }, [_vm._v(" Suivi commercial ")]) : _vm._e(), !_vm.isAllowedSUPERADMIN ? _c(VBtn, {
    staticClass: "mx-5 my-1 white--text",
    attrs: {
      "plain": "",
      "to": {
        name: 'UserProfile'
      }
    }
  }, [_vm._v(" Profil ")]) : _vm._e(), _c(VDivider, {
    staticClass: "my-3"
  }), _c(VBtn, {
    staticClass: "mx-5 white--text",
    attrs: {
      "plain": "",
      "bottom": "",
      "loading": _vm.loadingLogoutButton
    },
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_vm._v(" Déconnexion ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }