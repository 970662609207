import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VFooter, {
    attrs: {
      "absolute": "",
      "padless": "",
      "app": ""
    }
  }, [_c(VContainer, [_c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_vm._v(" OCF x Polyenco ")])], 1), _c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_vm._v(" Guillaume VILLEDIEU - Adrien GARBANI ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }