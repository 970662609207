export default {
    namespaced: true,

    state: {
        previousRoute: 'EstimateList',
    },

    mutations: {
        SET_ROUTE(state, route) {
            state.previousRoute = route;
        },
    },

    actions: {
        saveRoute({ commit }, route) {
            return Promise.resolve()
            .then(() => {
                commit('SET_ROUTE', route);
            });
        },
    },

    getters: {
        getPreviousRoute(state) {
            return state.previousRoute;
        },
    },
};