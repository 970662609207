export default {
    namespaced: true,

    state: {
        objects: [],
    },

    mutations: {
        SET_OBJECTS(state, objects) {
            state.objects = objects;
        },
        PUSH_OBJECT(state, object) {
            state.objects.push(object);
        },
    },

    actions: {
        showPopup({ commit }, { text, color }) {
            return Promise.resolve()
            .then(() => {
                commit('PUSH_OBJECT', { message: { text, color }, color });
            });
        },
    },

    getters: {
        getObjects(state) {
            return state.objects;
        },
    },
};