import Vue from 'vue';
import VueRouter from 'vue-router';

import roles from './roles';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { role: roles.NONE },
    component: () => import('./containers/Login.vue'),
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    meta: { role: roles.NONE },
    component: () => import('./containers/Forbidden.vue'),
  },
  {
    path: '/user/list',
    name: 'UserList',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/user/UserList.vue'),
  },
  {
    path: '/log',
    name: 'Log',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/Log.vue'),
  },
  {
    path: '/estimate',
    name: 'EstimateList',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EstimateList.vue'),
    props: () => ({ listAllEstimates: false, showArchived: false, readonly: false }),
  },
  {
    path: '/estimates',
    name: 'AllEstimateList',
    meta: { role: roles.SUPERVISOR },
    component: () => import('./containers/estimate/EstimateList.vue'),
    props: () => ({ listAllEstimates: true, showArchived: false, readonly: false }),
  },
  {
    path: '/archive',
    name: 'EstimateArchivedList',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EstimateList.vue'),
    props: () => ({ listAllEstimates: false, showArchived: true, readonly: true }),
  },
  {
    path: '/archives',
    name: 'AllEstimateArchivedList',
    meta: { role: roles.SUPERVISOR },
    component: () => import('./containers/estimate/EstimateList.vue'),
    props: () => ({ listAllEstimates: true, showArchived: true, readonly: true }),
  },
  {
    path: '/estimate/edit/:id',
    name: 'EstimateEdit',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EditEstimate.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false }),
  },
  {
    path: '/estimate/show/:id',
    name: 'EstimateShow',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EditEstimate.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: true }),
  },
  {
    path: '/estimate/edit/:estimateId/showcase/:id',
    name: 'ShowcaseEstimateEdit',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EditShowcaseEstimate.vue'),
    props: route => ({ estimateId: Number.parseInt(route.params.estimateId), id: Number.parseInt(route.params.id), readonly: false }),
  },
  {
    path: '/estimate/show/:estimateId/showcase/:id',
    name: 'ShowcaseEstimateShow',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/EditShowcaseEstimate.vue'),
    props: route => ({ estimateId: Number.parseInt(route.params.estimateId), id: Number.parseInt(route.params.id), readonly: true }),
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    meta: { role: roles.USER },
    component: () => import('./containers/user/UserProfile.vue'),
  },
  {
    path: '/backoffice/showcase',
    name: 'BackofficeShowcase',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/showcase/ShowcaseList.vue'),
  },
  {
    path: '/backoffice/showcase/edit/:id',
    name: 'BackofficeShowcaseEdit',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/showcase/EditShowcase.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false, copy: false }),
  },
  {
    path: '/backoffice/showcase/copy/:id',
    name: 'BackofficeShowcaseCopy',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/showcase/EditShowcase.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false, copy: true }),
  },
  {
    path: '/backoffice/showcase/show/:id',
    name: 'BackofficeShowcaseShow',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/showcase/EditShowcase.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: true, copy: false }),
  },
  {
    path: '/backoffice/option',
    name: 'BackofficeOption',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/option/OptionList.vue'),
  },
  {
    path: '/backoffice/option/edit/:id',
    name: 'BackofficeOptionEdit',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/option/EditOption.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false, copy: false }),
  },
  {
    path: '/backoffice/option/copy/:id',
    name: 'BackofficeOptionCopy',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/option/EditOption.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false, copy: true }),
  },
  {
    path: '/backoffice/option/show/:id',
    name: 'BackofficeOptionShow',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/option/EditOption.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: true, copy: false }),
  },
  {
    path: '/estimate/detail/edit/:id',
    name: 'DetailEstimateEdit',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/DetailEstimate.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: false }),
  },
  {
    path: '/estimate/detail/show/:id',
    name: 'DetailEstimateShow',
    meta: { role: roles.USER },
    component: () => import('./containers/estimate/DetailEstimate.vue'),
    props: route => ({ id: Number.parseInt(route.params.id), readonly: true }),
  },
  {
    path: '/backoffice/export/param',
    name: 'BackOfficeExportParam',
    meta: { role: roles.SUPERADMIN },
    component: () => import('./containers/backoffice/ExportParam.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// navigation guard //
import store from './store';

router.beforeEach((to, from, next) => {
  return store.dispatch('user/loadCurrentAuthenticatedIfUndefined').then(() => 
  {
    const isAuthenticated = store.getters['user/isAuthenticated'];
    const user = store.getters['user/getCurrentUser'];

    if (to.name !== 'Login' && !isAuthenticated)    { next({ name: 'Login'        }); return; } // if not authenticated, redirect to Login
    if (to.name === 'Login' &&  isAuthenticated)    { next({ name: 'EstimateList' }); return; } // if already authenticated, redirect to Home
    if (to.name == null)                            { next({ name: 'EstimateList' }); return; } // if unknown route, redirect to Home
    if (user && to.meta.role.rank > user.role.rank) { next({ name: 'Forbidden'    }); return; } // if not enough privilege, redirect to Forbidden
 
    next();
  });
});

export default router;
