import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbars', {
    staticClass: "mb-5",
    attrs: {
      "objects": _vm.objects,
      "timeout": 5000
    },
    on: {
      "update:objects": function ($event) {
        _vm.objects = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _ref$message = _ref.message,
            text = _ref$message.text,
            color = _ref$message.color;
        return [_c(VIcon, {
          attrs: {
            "left": "",
            "dark": "",
            "large": ""
          }
        }, [_vm._v(" " + _vm._s(color == 'error' ? 'mdi-alert-circle-outline' : 'mdi-checkbox-marked-circle-outline') + " ")]), _vm._v(" " + _vm._s(text) + " ")];
      }
    }, {
      key: "action",
      fn: function (_ref2) {
        var close = _ref2.close;
        return [_c(VBtn, {
          attrs: {
            "text": ""
          },
          on: {
            "click": close
          }
        }, [_vm._v(" Fermer ")])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }