import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        optionList: [],
        option: null,
        populatedOptions: {},

        categoryList: [],
    },

    mutations: {
        SET_OPTION_LIST(state, list){
            state.optionList = list;
        },
        UPDATE_OPTION_FROM_LIST(state, option) {
            const i = state.optionList.findIndex(el => el.id === option.id);
            state.optionList.splice(i, 1, option);
        },
        ADD_OPTION_TO_LIST(state, option) {
            state.optionList.push(option);
        },
        REMOVE_OPTION_FROM_LIST(state, id) {
            const i = state.optionList.findIndex(el => el.id === id);
            state.optionList.splice(i, 1);
        },
        SET_OPTION(state, option) {
            state.option = option;
        },
        SET_POPULATED_OPTION(state, option) {
            // state.populatedOptions[option.id] = option;
            // exept Vue is made aware of the change and update computed properties acordingly
            Vue.set(state.populatedOptions, option.id, option);
        },
        SET_CATEGORY_LIST(state, list){
            state.categoryList = list;
        },
        UPDATE_CATEGORY_FROM_LIST(state, category) {
            const i = state.categoryList.findIndex(el => el.id === category.id);
            state.categoryList.splice(i, 1, category);
        },
        ADD_CATEGORY_TO_LIST(state, category) {
            state.categoryList.push(category);
        },
        REMOVE_CATEGORY_FROM_LIST(state, id) {
            const i = state.categoryList.findIndex(el => el.id === id);
            state.categoryList.splice(i, 1);
        },
    },

    actions: {
        loadAllOptionList({ commit }) {
            return Vue.axios.get('/api/options')
            .then(response => {
                commit('SET_OPTION_LIST', response.data);
            });
        },

        clearOption({ commit }) {
            return Promise.resolve().then(() => {
                commit('SET_OPTION', null);
            });
        },

        createOption({ commit }, option) {
            return Vue.axios.post('api/option', option)
            .then(response => {
               commit('ADD_OPTION_TO_LIST', { ...option, id: response.data.id });
            });
        },

        deleteOption({ commit }, { id }){
            return Vue.axios.delete(`/api/option/${id}`)
            .then(() => {
                commit('REMOVE_OPTION_FROM_LIST', id);
            });
        },

        editOption({ commit }, option){
            return Vue.axios.put(`/api/option/${option.id}`, option)
            .then(() => {
                commit('UPDATE_OPTION_FROM_LIST', option);
            });
        },

        loadOption({ commit }, { id }) {
            return Vue.axios.get(`/api/option/${id}`)
            .then(response => {
                commit('SET_OPTION', response.data);
            });
        },

        populateOptions({ commit, state }, ids) {
            let promises = [];
            for(let id of ids) {
                if(id !== 0 && state.populatedOptions[id] == undefined) {
                    promises.push(Vue.axios.get(`/api/option/${id}`)
                    .then(response => {
                        commit('SET_POPULATED_OPTION', response.data);
                    }));
                }
            }
            return Promise.all(promises);
        },

        loadAllCategoryList({ commit }) {
            return Vue.axios.get('/api/option/categories')
            .then(response => {
                commit('SET_CATEGORY_LIST', response.data);
            });
        },

        createCategory({ commit }, category){
            return Vue.axios.post('api/option/category', category)
            .then(response => {
                commit('ADD_CATEGORY_TO_LIST', { ...category, id: response.data.id });
            });
        },

        editCategory({ commit }, category){
            return Vue.axios.put(`api/option/category/${category.id}`, category)
            .then(() => {
                commit('UPDATE_CATEGORY_FROM_LIST', category);
            });
        },

        deleteCategory({ commit }, { id }) {
            return Vue.axios.delete(`/api/option/category/${id}`)
            .then(() => {
                commit('REMOVE_CATEGORY_FROM_LIST', id);
            });
        },

        increaseOptionPrice(_, { amount, ids, unit }){
            return Vue.axios.patch('/api/options', { 
                amount,
                option_ids: ids,
                unit,
            });
        },
    },

    getters: {
        getOptionList(state) {
            return state.optionList;
        },
        getOption(state) {
            return state.option;
        },
        getPopulatedOptions(state) {
            return state.populatedOptions;
        },
        getCategoryList(state) {
            return state.categoryList;
        },
    },
};