import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        showcaseList: [],
        showcase: null,

        categoryList: [],
    },

    mutations: {
        SET_SHOWCASE_LIST(state, list) {
            state.showcaseList = list;
        },
        REMOVE_SHOWCASE_FROM_LIST(state, id) {
            const i = state.showcaseList.findIndex(el => el.id === id);
            state.showcaseList.splice(i, 1);
        },
        UPDATE_SHOWCASE_FROM_LIST(state, showcase) {
            const i = state.showcaseList.findIndex(el => el.id === showcase.id);
            state.showcaseList.splice(i, 1, showcase);
        },
        ADD_SHOWCASE_TO_LIST(state, showcase) {
            state.showcaseList.push(showcase);
        },
        SET_SHOWCASE(state, showcase) {
            state.showcase = showcase;
        },
        SET_CATEGORY_LIST(state, list){
            state.categoryList = list;
        },
        UPDATE_CATEGORY_FROM_LIST(state, category) {
            const i = state.categoryList.findIndex(el => el.id === category.id);
            state.categoryList.splice(i, 1, category);
        },
        ADD_CATEGORY_TO_LIST(state, category) {
            state.categoryList.push(category);
        },
        REMOVE_CATEGORY_FROM_LIST(state, id) {
            const i = state.categoryList.findIndex(el => el.id === id);
            state.categoryList.splice(i, 1);
        },
    },

    actions: {
        loadAllShowcaseList({ commit }) {
            return Vue.axios.get('/api/showcases')
            .then(response => {
                commit('SET_SHOWCASE_LIST', response.data);
            });
        },

        loadShowcase({ commit }, { id }) {
            return Vue.axios.get(`/api/showcase/${id}`)
            .then(response => {
                commit('SET_SHOWCASE', response.data);
            });
        },

        clearShowcase({ commit }) {
            return Promise.resolve().then(() => {
                commit('SET_SHOWCASE', null);
            });
        },

        createShowcase({ commit }, showcase){
            return Vue.axios.post('api/showcase', showcase)
            .then(response => {
                commit('ADD_SHOWCASE_TO_LIST', { ...showcase, id: response.data.id });
            });
        },

        editShowcase({ commit }, showcase){
            return Vue.axios.put(`api/showcase/${showcase.id}`, showcase)
            .then(() => {
                commit('UPDATE_SHOWCASE_FROM_LIST', showcase);
            });
        },

        deleteShowcase({ commit }, { id }) {
            return Vue.axios.delete(`/api/showcase/${id}`)
            .then(() => {
                commit('REMOVE_SHOWCASE_FROM_LIST', id);
            });
        },

        loadAllCategoryList({ commit }) {
            return Vue.axios.get('/api/showcase/categories')
            .then(response => {
                commit('SET_CATEGORY_LIST', response.data);
            });
        },

        createCategory({ commit }, category){
            return Vue.axios.post('api/showcase/category', category)
            .then(response => {
                commit('ADD_CATEGORY_TO_LIST', { ...category, id: response.data.id });
            });
        },

        editCategory({ commit }, category){
            return Vue.axios.put(`api/showcase/category/${category.id}`, category)
            .then(() => {
                commit('UPDATE_CATEGORY_FROM_LIST', category);
            });
        },

        deleteCategory({ commit }, { id }) {
            return Vue.axios.delete(`/api/showcase/category/${id}`)
            .then(() => {
                commit('REMOVE_CATEGORY_FROM_LIST', id);
            });
        },

        increaseShowcasePrice(_, { amount, ids, unit }){
            return Vue.axios.patch('/api/showcases', { 
                amount,
                showcase_ids: ids,
                unit,
            });
        },
    },

    getters: {
        getShowcaseList(state) {
            return state.showcaseList;
        },
        getShowcase(state) {
            return state.showcase;
        },
        getCategoryList(state) {
            return state.categoryList;
        },
    },
};