import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        estimateList: [],
        estimate: null,

        estimateStateList: [],
    },

    mutations: {
        SET_ESTIMATE_LIST(state, list){
            state.estimateList = list;
        },
        UPDATE_ESTIMATE_FROM_LIST(state, estimate) {
            const i = state.estimateList.findIndex(el => el.id === estimate.id);
            state.estimateList.splice(i, 1, { ...state.estimateList[i], ...estimate });
        },
        ADD_ESTIMATE_TO_LIST(state, estimate) {
            state.estimateList.push(estimate);
        },
        REMOVE_ESTIMATE_FROM_LIST(state, id) {
            const i = state.estimateList.findIndex(el => el.id === id);
            state.estimateList.splice(i, 1);
        },
        UPDATE_SHOWCASE_ESTIMATE_FROM_ESTIMATE(state, showcase) {
            const i = state.estimate.showcases.findIndex(el => el.id === showcase.id);
            state.estimate.showcases.splice(i, 1, { ...state.estimate.showcases[i], ...showcase });
        },
        ADD_SHOWCASE_ESTIMATE_TO_ESTIMATE(state, showcase) {
            state.estimate.showcases.push(showcase);
        },
        REMOVE_SHOWCASE_ESTIMATE_FROM_ESTIMATE(state, id) {
            const i = state.estimate.showcases.findIndex(el => el.id === id);
            state.estimate.showcases.splice(i, 1);
        },
        SET_ESTIMATE(state, estimate) {
            state.estimate = estimate;
        },
        UPDATE_DOCUMENT_ESTIMATE_FROM_ESTIMATE(state, document) {
            const i = state.estimate.documents.findIndex(el => el.id === document.id);
            state.estimate.documents.splice(i, 1, { ...state.estimate.documents[i], ...document });
        },
        ADD_DOCUMENT_ESTIMATE_TO_ESTIMATE(state, document) {
            state.estimate.documents.push(document);
        },
        REMOVE_DOCUMENT_ESTIMATE_FROM_ESTIMATE(state, id) {
            const i = state.estimate.documents.findIndex(el => el.id === id);
            state.estimate.documents.splice(i, 1);
        },
        ADD_OPTION_ESTIMATE_TO_ESTIMATE(state, option) {
            state.estimate.options.push(option);
        },
        REMOVE_OPTION_ESTIMATE_FROM_ESTIMATE(state, id) {
            const i = state.estimate.options.findIndex(el => el.id === id);
            state.estimate.options.splice(i, 1);
        },
        UPDATE_OPTION_ESTIMATE_FROM_ESTIMATE(state, option) {
            const i = state.estimate.options.findIndex(el => el.id === option.id);
            state.estimate.options.splice(i, 1, { ...state.estimate.options[i], ...option });
        },
        ADD_OPTION_ESTIMATE_TO_SHOWCASE_ESTIMATE(state, { showcase_id, option }) {
            const index = state.estimate.showcases.findIndex(el => el.id === showcase_id);
            state.estimate.showcases[index].options.push(option);
        },
        REMOVE_OPTION_ESTIMATE_FROM_SHOWCASE_ESTIMATE(state, { showcase_id, id }) {
            const index = state.estimate.showcases.findIndex(el => el.id === showcase_id);
            const i = state.estimate.showcases[index].options.findIndex(el => el.id === id);
            state.estimate.showcases[index].options.splice(i, 1);
        },
        UPDATE_OPTION_ESTIMATE_FROM_SHOWCASE_ESTIMATE(state, { showcase_id, option }) {
            const index = state.estimate.showcases.findIndex(el => el.id === showcase_id);
            const i = state.estimate.showcases[index].options.findIndex(el => el.id === option.id);
            state.estimate.showcases[index].options.splice(i, 1, { ...state.estimate.showcases[index].options[i], ...option });
        },
        SET_ESTIMATE_STATE_LIST(state, list){
            state.estimateStateList = list;
        },
        UPDATE_ESTIMATE_STATE_FROM_LIST(state, estimateState) {
            const i = state.estimateStateList.findIndex(el => el.id === estimateState.id);
            state.estimateStateList.splice(i, 1, { ...state.estimateStateList[i], ...estimateState });
        },
        ADD_ESTIMATE_STATE_TO_LIST(state, estimateState) {
            state.estimateStateList.push(estimateState);
        },
        REMOVE_ESTIMATE_STATE_FROM_LIST(state, id) {
            const i = state.estimateStateList.findIndex(el => el.id === id);
            state.estimateStateList.splice(i, 1);
        },
    },

    actions: {
        loadAllEstimateList({ commit }) {
            return Vue.axios.get('/api/estimates')
                .then(response => {
                    commit('SET_ESTIMATE_LIST', response.data);
                });
        },

        clearEstimate({ commit }) {
            return Promise.resolve().then(() => {
                commit('SET_ESTIMATE', null);
            });
        },

        createEstimate({ commit }, estimate) {
            return Vue.axios.post('api/estimate', estimate)
                .then(response => {
                    commit('ADD_ESTIMATE_TO_LIST', { ...estimate, ...response.data });
                });
        },

        deleteEstimate({ commit }, { id }) {
            return Vue.axios.delete(`/api/estimate/${id}`)
                .then(() => {
                    commit('REMOVE_ESTIMATE_FROM_LIST', id);
                });
        },

        editEstimate({ commit }, estimate) {
            return Vue.axios.put(`/api/estimate/${estimate.id}`, estimate)
                .then(() => {
                    commit('UPDATE_ESTIMATE_FROM_LIST', estimate);
                });
        },

        loadEstimate({ commit }, { id }) {
            return Vue.axios.get(`/api/estimate/${id}`)
                .then(response => {
                    commit('SET_ESTIMATE', response.data);
                });
        },

        createShowcaseEstimate({ commit, state }, showcase) {
            return Vue.axios.post(`api/estimate/${state.estimate.id}/showcase`, showcase)
                .then(response => {
                    commit('ADD_SHOWCASE_ESTIMATE_TO_ESTIMATE', { ...showcase, ...response.data });
                    return { ...showcase, ...response.data };
                });
        },

        deleteShowcaseEstimate({ commit, state }, { id }) {
            return Vue.axios.delete(`/api/estimate/${state.estimate.id}/showcase/${id}`)
                .then(() => {
                    commit('REMOVE_SHOWCASE_ESTIMATE_FROM_ESTIMATE', id);
                });
        },

        editShowcaseEstimate({ commit, state }, showcase) {
            return Vue.axios.put(`api/estimate/${state.estimate.id}/showcase/${showcase.id}`, showcase)
                .then(() => {
                    commit('UPDATE_SHOWCASE_ESTIMATE_FROM_ESTIMATE', showcase);
                });
        },

        uploadDocument({ commit, state }, { file }) {
            let formData = new FormData();
            formData.append('file', file);

            return Vue.axios.post(`/api/estimate/${state.estimate.id}/document/upload`, formData)
            .then(response => {
                commit('ADD_DOCUMENT_ESTIMATE_TO_ESTIMATE', { ...response.data });
            });
        },

        deleteDocumentEstimate({ commit, state }, { id }) {
            return Vue.axios.delete(`/api/estimate/${state.estimate.id}/document/${id}`)
                .then(() => {
                    commit('REMOVE_DOCUMENT_ESTIMATE_FROM_ESTIMATE', id);
                });
        },

        editDocumentEstimate({ commit, state }, document) {
            return Vue.axios.put(`api/estimate/${state.estimate.id}/document/${document.id}`, document)
                .then(() => {
                    commit('UPDATE_DOCUMENT_ESTIMATE_FROM_ESTIMATE', document);
                });
        },

        createOptionEstimate({ commit, state }, option) {
            return Vue.axios.post(`api/estimate/${state.estimate.id}/option`, option)
                .then(response => {
                    commit('ADD_OPTION_ESTIMATE_TO_ESTIMATE', { ...option, ...response.data });
                });
        },

        deleteOptionEstimate({ commit, state }, { id }) {
            return Vue.axios.delete(`/api/estimate/${state.estimate.id}/option/${id}`)
                .then(() => {
                    commit('REMOVE_OPTION_ESTIMATE_FROM_ESTIMATE', id);
                });
        },

        editOptionEstimate({ commit, state }, option) {
            return Vue.axios.put(`api/estimate/${state.estimate.id}/option/${option.id}`, option)
                .then(() => {
                    commit('UPDATE_OPTION_ESTIMATE_FROM_ESTIMATE', option);
                });
        },

        createShowcaseOptionEstimate({ commit, state }, { showcase_id, option }) {
            return Vue.axios.post(`api/estimate/${state.estimate.id}/showcase/${showcase_id}/option`, option)
                .then(response => {
                    commit('ADD_OPTION_ESTIMATE_TO_SHOWCASE_ESTIMATE', { showcase_id, option: { ...option, ...response.data } });
                });
        },

        deleteShowcaseOptionEstimate({ commit, state }, { showcase_id, option: { id } }) {
            return Vue.axios.delete(`/api/estimate/${state.estimate.id}/showcase/${showcase_id}/option/${id}`)
                .then(() => {
                    commit('REMOVE_OPTION_ESTIMATE_FROM_SHOWCASE_ESTIMATE', { showcase_id, id });
                });
        },

        editShowcaseOptionEstimate({ commit, state }, { showcase_id, option }) {
            return Vue.axios.put(`api/estimate/${state.estimate.id}/showcase/${showcase_id}/option/${option.id}`, option)
                .then(() => {
                    commit('UPDATE_OPTION_ESTIMATE_FROM_SHOWCASE_ESTIMATE', { showcase_id, option });
                });
        },

        archiveEstimate({ commit }, { id, archived }) {
            return Vue.axios.put(`/api/estimate/archive/${id}`, { archived })
                .then(() => {
                    commit('UPDATE_ESTIMATE_FROM_LIST', { id, archived });
                });
        },

        loadAllEstimateStateList({ commit }) {
            return Vue.axios.get('/api/estimate/states')
            .then(response => {
                commit('SET_ESTIMATE_STATE_LIST', response.data);
            });
        },

        createEstimateState({ commit }, estimateState){
            return Vue.axios.post('api/estimate/state', estimateState)
            .then(response => {
                commit('ADD_ESTIMATE_STATE_TO_LIST', { ...estimateState, id: response.data.id });
            });
        },

        editEstimateState({ commit }, estimateState){
            return Vue.axios.put(`api/estimate/state/${estimateState.id}`, estimateState)
            .then(() => {
                commit('UPDATE_ESTIMATE_STATE_FROM_LIST', estimateState);
            });
        },

        deleteEstimateState({ commit }, { id }) {
            return Vue.axios.delete(`/api/estimate/state/${id}`)
            .then(() => {
                commit('REMOVE_ESTIMATE_STATE_FROM_LIST', id);
            });
        },
    },

    getters: {
        getEstimateList(state) {
            return state.estimateList;
        },
        getEstimate(state) {
            return state.estimate;
        },
        getDocumentDataPath(state) {
            return ({ id }) => `/api/estimate/${state.estimate.id}/document/${id}/download`;
        },
        getEstimateStateList(state) {
            return state.estimateStateList;
        },
    },
};