import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VApp, [_c('NavBar', {
    staticClass: "hidden-sm-and-down"
  }), _c('NavDrawer', {
    staticClass: "hidden-md-and-up"
  }), _c(VMain, [_c('router-view')], 1), _c('Footer'), _c('LoginDialog'), _c('PopUp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }