import user from './user';
import showcase from './showcase';
import option from './option';
import estimate from './estimate';
import log from './log';
import popup from './popup';
import exportDoc from '@/modules/exportDoc';
import routeMemory from '@/modules/routeMemory';
import paymentMethod from '@/modules/paymentMethod';

const modules = { user, showcase, option, log, popup, estimate, exportDoc, paymentMethod, routeMemory };

export default modules;