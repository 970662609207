import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        exportList: [],
        estimateId: null,
        exportParam: [],
        tva: null,
    },

    mutations: {
        SET_ESTIMATE_ID(state, estimateId) {
            state.estimateId = estimateId;
        },
        ADD_EXPORT_TO_LIST(state, exportData) {
            state.exportList.push(exportData);
        },
        SET_EXPORT_LIST(state, list) {
            state.exportList = list;
        },
        REMOVE_EXPORT_FROM_LIST(state, id) {
            const i = state.exportList.findIndex(el => el.id === id);
            state.exportList.splice(i, 1);
        },
        SET_EXPORT_PARAM(state, list) {
            state.exportParam = list;
        },
        UPDATE_EXPORT_PARAM(state, list) {
            for(let param of list.texts){
                state.exportParam[param.id] = param.text;
            }

            for (let dateParam of list.lifetime){
                state.exportParam[dateParam.id + 4] = dateParam.text;
            }
        },
        UPDATE_EXPORT_PARAM_DOCUMENT(state, { text, id }) {
            const i = state.exportParam.findIndex(el => el.id === id);
            state.exportParam[i].text = text;
        },
        UPDATE_TVA(state, tva) {
            state.tva = tva;
        },
    },

    actions: {
        exportEstimate({ commit, state }, exportData ) {
            return Vue.axios.post(`api/export/estimate/${state.estimateId}`, exportData)
                .then(response => {
                    commit('ADD_EXPORT_TO_LIST', response.data);
                    let url = window.location.origin;
                    let documentType = exportData.toPdf ? 'pdf' : 'word';
                    window.open(`${url}/api/export/${response.data.id}/${documentType}`, '_blank').focus();
                });
        },

        loadExportList({ commit }, { estimate_id }) {
            return Vue.axios.get(`api/export/estimate/${estimate_id}`)
                .then(response => {
                    commit('SET_ESTIMATE_ID', estimate_id);
                    commit('SET_EXPORT_LIST', response.data);
                });
        },

        clearExportList({ commit }) {
            return Promise.resolve().then(() => {
                commit('SET_EXPORT_LIST', null);
            });
        },

        deleteExport({ commit }, { id }) {
            return Vue.axios.delete(`/api/export/${id}`)
                .then(() => {
                    commit('REMOVE_EXPORT_FROM_LIST', id);
                });
        },

        getAllExportParam({ commit }) {
            return Vue.axios.get('/api/export/params')
                .then(response => {
                    commit('SET_EXPORT_PARAM', response.data);
                });
        },

        editExportText({ commit }, texts) {
            return Vue.axios.put('/api/export/params', texts)
                .then(() => {
                    commit('UPDATE_EXPORT_PARAM', texts);
                });
        },

        upload({ commit }, { file, id }) {
            let formData = new FormData();
            formData.append('file', file);

            return Vue.axios.post(`/api/export/params/upload/${id}`, formData)
                .then(response => {
                   commit('UPDATE_EXPORT_PARAM_DOCUMENT', { text: response.data.name, id });
                });
        },

        exportCataloguePDF(_, exportParamCat ) {
            let url = window.location.origin;
            window.open(url + `/api/export/catalogue/pdf?language=${exportParamCat.language}&privateExport=${exportParamCat.privateExport}`, '_blank').focus();
        },

        exportCatalogueWord(_, exportParamCat ) {
            let url = window.location.origin;
            window.open(url + `/api/export/catalogue/word?language=${exportParamCat.language}&privateExport=${exportParamCat.privateExport}`, '_blank');
        },

        getTva({ commit }) {
            return Vue.axios.get(`/api/export/param/key/${'TVA'}`)
                .then(response => {
                    commit('UPDATE_TVA', response.data[0].number);
                });
        },
    },

    getters: {
        getExportList(state) {
            return state.exportList;
        },
        getEstimateId(state) {
            return state.estimateId;
        },
        getExportParam(state) {
            return state.exportParam;
        },
        getTva(state) {
            return state.tva;
        },
    },
};