import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        paymentMethode: [],
    },

    mutations: {
        SET_PAYMENT_METHODE(state, list) {
            state.paymentMethode = list;
        },
        ADD_PAYMENT_METHODE_TO_LIST(state, payment) {
            state.paymentMethode.push(payment);
        },
        UPDATE_PAYMENT_METHODE_FROM_LIST(state, payment) {
            const i = state.paymentMethode.findIndex(el => el.id === payment.id);
            state.paymentMethode.splice(i, 1, { ...state.paymentMethode[i], ...payment });
        },
        DELETE_PAYMENT_METHODE_FROM_LIST(state, id) {
            const i = state.paymentMethode.findIndex(el => el.id === id);
            state.paymentMethode.splice(i, 1, { ...state.paymentMethode[i], deleted: true });
        },
    },

    actions: {
        getAllPaymentMethode({ commit }) {
            return Vue.axios.get('/api/payment')
                .then(response => {
                    commit('SET_PAYMENT_METHODE', response.data);
                });
        },

        createPaymentMethode({ commit }, editedItem) {
            return Vue.axios.post('/api/payment', editedItem)
                .then(response => {
                    commit('ADD_PAYMENT_METHODE_TO_LIST', response.data );
                });
        },

        editPaymentMethode({ commit }, editedItem) {
            return Vue.axios.put('/api/payment', editedItem)
                .then(() => {
                    commit('UPDATE_PAYMENT_METHODE_FROM_LIST', editedItem);
                });
        },

        deletePaymentMethode({ commit }, { id }) {
            return Vue.axios.delete(`/api/payment/${id}`)
                .then(() => {
                    commit('DELETE_PAYMENT_METHODE_FROM_LIST', id);
                });
        },
    },

    getters: {
        getPaymentMethode(state) {
            return state.paymentMethode.filter(it => !it.deleted);
        },
    },
};