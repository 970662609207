<template>
  <v-snackbars
    :objects.sync="objects"
    class="mb-5"
    :timeout="5000"
  >
    <template #default="{ message: { text, color } }">
      <v-icon
        left
        dark
        large
      >
        {{ color == 'error' ? 'mdi-alert-circle-outline' : 'mdi-checkbox-marked-circle-outline' }}
      </v-icon>

      {{ text }}
    </template>
    <template #action="{ close }">
      <v-btn
        text
        @click="close"
      >
        Fermer
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VSnackbars from 'v-snackbars';

export default {
  name: 'PopUp',

  components: {
    'v-snackbars': VSnackbars,
  },

  computed: {
    ...mapGetters('popup', {
      getObjects: 'getObjects',
    }),

    objects: {
      get() {
        return this.getObjects;
      },
      set(value) {
        this.setObjects(value);
      },
    },
  },

  methods: {
    ...mapMutations('popup', {
      setObjects: 'SET_OBJECTS',
    }),
  },
};
</script>